@import '../../assets/sass/breakpoints';
@import '../../assets/sass/variables';


.container {
    // background-color: wheat;
    margin-bottom: 40px;
    min-height: min-content;

    @media (min-width: $break-small) {
        // min-height: calc(100vh - 150px);
        padding-bottom: 0px;
    }

    .section {
        position: relative;
        z-index: -2;
        background-color: $primary;
        // margin: 0 20px;

        @media (min-width: $break-small) {
            margin: auto;
        }

        @media (min-width: $break-medium) {
        }
    }

 
    .gridAjust {
        grid-auto-rows: minmax(100px, auto);
        position: relative;

        @media (min-width: $break-small) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: $break-medium) {
            grid-template-columns: repeat(12, 1fr);
            grid-auto-rows: minmax(100px, auto);
        }

    }


    .titleContainer {
        grid-column: 1;
        grid-row: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px 0px;
        margin-top: 20px;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            margin-top: 0px;
            grid-column: 1 / span 6;
            grid-row: 2;
            height: 200px;
            justify-content: flex-start;
            // margin-top: 30px;

        }

        .Title {
            width: 70%;
            @media (min-width: $break-medium) {
                width: 100%;
                max-width: 700px;
                position: absolute;
                top: 5%;
                z-index: 20;
            }
        }

        .Flowa7 {
           display: none;

            @media (min-width: $break-medium) {
                display: block;
                width: 40%;
                position: absolute;
                // top: 5%;
                left: -80px;
                z-index: 10;
                width: 100%;
                max-width: 500px;

                img {
                    transform: rotate(100deg);
                }
            }
        }

        .Flowa8 {
            display: none;
         
            @media (min-width: $break-medium) {
                display: block;
                width: 40%;
                position: absolute;
                bottom: -10%;
                right: 10%;
                z-index: -1;
                width: 100%;
                max-width: 400px;

            }

            img {
                transform: rotate(60deg);

            }
        }
    }

    .cardAjust {
        grid-column: 1 ;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            grid-column: 1 / span 13;
            // grid-row: 2 / span 3;
            margin-right: 20px;
            margin-left: 20px;

         }
    }


   
    .cardAjust1 {
        // letter-spacing: 1.1px;
        position: relative;
        z-index: 10;
        grid-row: 2 / span 1;
        z-index: 16;
        margin-top: 1.4vw;

        @media (min-width: $break-small) {
            margin-top: 30px;
        }

        @media (min-width: $break-medium) {
            margin-top: 30px;
            grid-column: 1 / span 6;
            grid-row: 1 ;
         }

        @media (min-width: $break-large) {
            grid-column: 1 / span 6;
            // grid-row: 2 / span 3;
         }
    }

    .flowerContainer {
        grid-column: 1;
        grid-row: 3 / span 1;
        position: relative;
        min-height: 150px;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            grid-column: 6 / span 7;
            grid-row:1 / span 3;
        }

        .Flowa1 {
            position: absolute;
            top: -18%;
            right: 0%;
            width: 80%;
            max-width: 220px;
            transform: rotate(0deg);
            z-index: 17;

            @media (min-width: $break-small) {
            
            }

            @media (min-width: $break-medium) {
                transform: rotate(10deg);
                grid-column: 1 / span 3;
                top: -5%;
                right: 10%;
                z-index: 0;
                max-width: 320px;
            }
        }

        .Flowa4 {
            position: absolute;
            top: -13%;
            right: 0%;
            width: 80%;
            max-width: 220px;
            transform: rotate(-150deg);
            z-index: 6;

            @media (min-width: $break-small) {
            
            }

            @media (min-width: $break-medium) {
                transform: rotate(40deg) scaleX(-1);
                grid-column: 1;
                top: 70px;
                right: -40px;
                z-index: -1;
                width: 30%;
                max-width: 270px;
            }
        }
    }

    .cardAjust2 {
        // letter-spacing: 1.1px;
        position: relative;
        margin-bottom: 40px;
        z-index: 15;
        grid-row: 4 / span 1;
        z-index: 15;
        @media (min-width: $break-medium) {
            grid-column: 7 / span 6;
            grid-row: 1 / span 4;
            margin-top: 30%;
         }

        @media (min-width: $break-large) {

        }

        // .Flowa8 {
        //     display: none;
        //     @media (min-width: $break-small) {
               
        //     }
     
        //      @media (min-width: $break-medium) {
        //          display: block;
        //          grid-column: 1;
        //          position: absolute;
        //          grid-column: 1;
        //          bottom: -40px;
        //          right: -20px;
        //          width: 80%;
        //          max-width: 170px;
        //         //  transform: scaleX(-1) rotate(-20deg);
        //          z-index: -1;
        //      }
        //  }


        .Flowa8 {
            display: none;
            @media (min-width: $break-small) {
               
            }
     
             @media (min-width: $break-medium) {
                 display: block;
                 grid-column: 1;
                 position: absolute;
                 grid-column: 1;
                 bottom: -40px;
                 right: -20px;
                 width: 80%;
                 max-width: 170px;
                //  transform: scaleX(-1) rotate(-20deg);
                 z-index: -1;
             }
         }
    }
   
    .cardAjustYellow {
        position: relative;
        background-color: $tertiary;
        margin-bottom: 20px;
        color: $deep-green;
        font-weight: $font-weight-second-card;
        text-align: center;
        grid-row: 5 / span 1;
        font-weight: 700;
        font-size: 18px;

        @media (min-width: $break-medium) {
            margin-top: 50px;
            grid-column: 1 / span 10;
            grid-row: 4 ;
            font-size: 24px;
            margin-top: 80px;
            margin-bottom: 50px;

        }

        @media (min-width: $break-large) {
            grid-column: 1 / span 6;
            grid-row: 3 ;
        }

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            border: 20px solid $quaternary;
            border-radius: 20px;
            box-sizing: border-box;
            position: absolute;
            top: -30px;
            left: -35px;
            z-index: -1;
        }

        &:after {
            content: '';
            width: 150px;
            height: 150px;
            position: absolute;
            background: center / contain no-repeat url("../../assets/svg/flowa1.svg");
            top: -80px;
            left: -70px;
            transform: rotate(-80deg);
            z-index: -1;

            @media (min-width: $break-medium) {
                transform: rotate(-60deg);
                top: 70%;
                z-index: 10;
                left: -80px;
                width: 300px;
                height: 180px;
            }
        }
    }

    .cardAjustTarif {
        margin-bottom: 50px;
        // letter-spacing: 1.1px;
        // position: relative;
        // z-index: 10;
        // grid-row: 2 / span 1;
        // z-index: 16;
        // margin-top: 1.4vw;

        h2 {
            margin-top: 0;
            position: relative;
            display: inline;
            font-size: 3rem;
            &:before {
                left: -50px;
                transform: rotate(-60deg);
                @extend .flowa-tarif;
            }

            &:after {
                right: -50px;
                transform: rotate(-20deg);
                @extend .flowa-tarif;
            }
        }

        //commons class for images around tarif title
        .flowa-tarif {
            content: '';
            position: absolute;
            bottom: 10%;
            background: center / contain no-repeat url("../../assets/svg/flowa1.svg");
            width: 30px;
            height: 30px;
        }



        @media (min-width: $break-medium) {
            text-align: center;
    
        }

        .seances-container {
            .seance {
                position: relative;
                &:first-child {
                    padding-bottom: 50px;
                    margin-bottom: 50px;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        transform: translateX(-50%);
                        width: 70%;
                        height: 1px;
                        background-color: rgba(255, 255, 255, 0.466);
                    }
                }

                &:last-child {
                    h4 {
                        margin-bottom: 10px;
                    }
                }

                h5 {
                    margin: 0px;
                }

                .info-seance {
                    font-size: 12px;
                }
            }

        }

        @media (min-width: $break-medium) {
           grid-column: 4 / span 8;
           grid-row: 5;
           margin-bottom: 50px;
        }

        // @media (min-width: $break-medium) {
        //     margin-top: 30px;
        //     grid-column: 1 / span 6;
        //     grid-row: 1 ;
        //  }

        @media (min-width: $break-large) {
            grid-column: 4 / span 8;
            grid-row: 4;
        }
    }


    .Flowa2 {
        position: absolute;
        grid-column: 1;
        top: 10%;
        left: -70px;
        width: 80%;
        max-width: 300px;
        transform: rotate(100deg);
        z-index: 9;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
            transform: translate(-0px, 0px) rotate(10deg);
            grid-column: 1;
            display: none;
        }
    }
    .Flowa3 {
        position: absolute;
        grid-column: 1;
        top: 15%;
        left: 0%;
        width: 60%;
        max-width: 300px;
        transform: rotate();
        z-index: 9;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
            display: none;
        }
    }

    .Flowa5 {
       display: none;
       z-index: -1;
        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
            display: block;
            grid-column: 1;
            position: absolute;
            grid-column: 1;
            top: -90px;
            right: -60px;
            width: 40%;
            // max-width: 300px;
            transform: scaleX(-1) rotate(-20deg);
            z-index: -1;
        }
    }
}
