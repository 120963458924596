@import '../../assets/sass/breakpoints';
.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: white;
    z-index: 200;
    color: rgba(0, 0, 0, 0.794);

    .closeButtonContainer {
        position: relative;
        cursor: pointer;

        

        .closeButton {
            width: 30px;
            height: 30px;
            position: absolute;
            margin: 20px;
            right: 0;

            &:hover {
                div {
                    background-color: rgb(136, 136, 136);

                }
            }

            div {
                width: 100%;
                height: 2px;
                background-color: rgb(185, 185, 185);
                position: absolute;
                top: 50%;
                right: 0;
                border-radius: 20px;

              

                &:nth-child(1) {
                    transform: translateY(-50%) rotate(45deg);
                }

                &:nth-child(2) {
                    transform: translateY(-50%) rotate(-45deg);
                }
            }
        }
    }
    .mentions {
        width: 80%;
        margin: 40px auto;
        @media (min-width: $break-medium) {
            
            max-width: 900px;
        }

        .flex-box {
            margin-bottom: 100px;

            h1 {
                margin-bottom: 100px;
                // text-align: center;
            }

            h3 {
                margin-bottom: 30px;
            }

        }
    }
  
    // .mentions {
    //     max-width: 900px;
    //     // margin: 0 auto;

       

    //     .flex-box {
    //         margin-bottom: 100px;
    //         max-width: 800px;
    //         h1 {
    //             margin-bottom: 100px;
    //             // text-align: center;
    //         }

    //         h3 {
    //             margin-bottom: 30px;
    //         }
    //     }
    // }

  
}

.hideContainer {
    display: none;
}