@import '../../assets/sass/variables';

.container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: $quaternary;
    z-index: 200;

    .svgWrapper {
        position: absolute;
        transition: 1s;
        opacity: 1;
        width: 50%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }
    img {
        // position: absolute;
        transition: 1s;
        opacity: 1;
        width: 100%;
    }
}

.addAnimeLeaveWelcome {
    animation: openSite 2s linear;
    animation-fill-mode: forwards;
}

@keyframes openSite {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        background-color: rgba(180, 51, 51, 0);
        z-index: -1;
    }
}