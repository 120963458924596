@import '../../assets/sass/variables';

.container {
    width: 100%;
    height: 100vh;
    background-color: $quaternary;
    // background: center / contain no-repeat url("../../assets/svg/logofanny5.svg")  $quaternary;
    background-image:  url("../../assets/svg/logofanny5.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-color: $quaternary;
    background-position: center;
}