$font-color-second-card: black;
$font-weight-second-card: 600;

$primary: #98B8AA;
$secondary: #546660;
$deep-green: #546660;

$deepGreenInputComplete: #414644;

$tertiary: #D8AD63;
$quaternary: #F7CBCA;


$font-color-card: rgb(219, 219, 219);

$font-size-input: 16px;
$font-color-input: black;
$background-color-input: rgb(192, 200, 173);
$font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;