@import '../../assets/sass/breakpoints';
@import '../../assets/sass/variables';



.header {
    width: 100%;

    .inner-header {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 140px;

        @media (min-width: $break-small) {
            height: 150px;
        }

            .logo-container {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 50px;
                div {
                    width: 15vw;
                    min-width: 170px;
                    max-width: 220px;
                    svg {
                        width: 100%;

                      
                    }
                }
            }

            .navigation-container {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 300;
                animation-fill-mode: forwards;
                padding-right: 90px;
                &-overlay {
                    display: none;
                }
                // @media (max-width: 950px) {
                //     display: none;
                // }

                @media (min-width: 950px) {
                    position: unset;
                    margin: auto 0;
                }
    
                &-open {
                    background-color: rgba(0, 0, 0, 0.459);
                    opacity: 1;
                    z-index: 50;
                }
                &-close {
                    background-color: rgba(0, 0, 0, 0.459);
                    opacity: 0;
                    z-index: -1;
                }

                &-hidden {
                    display: none;
                }
    
                &-open, &-close {
                    @media (min-width: 950px) {
                        background-color: unset;
                        opacity: 1;
                        z-index: unset;
                    }
                }
                
                ul {
                    padding: 0;
                    width: 100vw;
                    height: 100vh;
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;
                    // padding-left: 30px;
                    margin: 0;

                    @media (max-width: 949px)  {
                        background-color: $quaternary;
                    }
                    @media (min-width: 540px) and (max-width: 949px) {
                        background-image: url('../../assets/svg/groupofflowa2.svg');
                        background-repeat: no-repeat;
                        background-position: top 50% right 50px;;
                        background-size: 40%;
                    }

                    @media (min-width: 950px) {
                        height: min-content;
                        width: unset;
                        flex-direction: row;
                    }
    
                    li {
                        position: relative;
                        cursor: pointer;

                        @media (max-width: 950px) {
                            padding:5px;
                            a {
                                font-size: 3rem;
                                font-weight: 500;

                            }
                            &:hover {
                                a {
                                    color: rgba(226, 169, 37, 0.89);
                                }
                            }  
                        }
                        a {
                            text-decoration: none;
                            color: rgb(255, 255, 255);

                            font-weight: 500;

                            @media (min-width: 950px) {
                            padding: 20px;

                                font-size: 14px;
                                color: rgb(24, 24, 24);

                            }  
                        }

                        span {
                            position: absolute;
                            display: block;
                            width: 0%;
                            height: 2px;
                            background-color: rgba(0, 0, 0, 0.568);
                            transform: translate(10px, 5px);
                            transition: 0.3s;
                        }
                        @media (min-width: 950px) {
                            &:hover {
                                span {
                                    width: 80%;
                                    transition: 0.3s;
                                }
                            }
                        }  
                      
                    }
                    li:first-child {
                        margin-top: auto;
                    }
                    li:last-child {
                        margin-bottom: auto;
                    }
                }
            }
    }
}

@keyframes openMenu {
    0% {
        display: none;
        opacity: 0;
    }
    100% {
        display: unset;
        opacity: 1;
    }
}

@keyframes closeMenu {
    0% {
        display: unset;
        opacity: 1;
    }
    99% {
        display: unset;
    }
    100% {
        display: none;
        opacity: 0;
    }
}



