@import '../../assets/sass/breakpoints';
@import '../../assets/sass/variables';


.isHiddenBurger {

    @media (min-width: 950px) {
        display: none;
    }
}


.burgerContainer {

    .menuBurger {
        width: 32px;
        height: 32px;
        background-color: $tertiary;
        border-radius: 50% 50%;
        position: fixed;
        top: 55px;
        right: 20px;
        z-index: 200;

        @media (min-width: $break-medium) {
            right: 40px;
        }
    
        &:hover {
            cursor: pointer;
            animation: spin 1s linear;
            animation-iteration-count: infinite;
        }      
    }


    @media (min-width: 950px) {
        .scroll-menu {
            position: fixed;
            top: 40px;
            right: 10%;
            z-index: 100;

            nav {
                transition: 0.5s;

                ul {
                    display: flex;
                    list-style-type: none;
                    padding: 0;

                    li {
                        cursor: pointer;
                        position: relative;
                        a {
                            text-decoration: none;
                            color: rgb(0, 0, 0);
                            font-weight: 500;
                            padding: 10px;
                            font-size: 14px;
                            padding: 30px 10px;
                        }
                        span {
                            position: absolute;
                            display: block;
                            width: 0%;
                            height: 2px;
                            background-color: rgba(0, 0, 0, 0.568);
                            transform: translate(10px, 5px);
                            transition: 0.3s;
                            z-index: -1;
                        }
                        @media (min-width: $break-medium) {
                            &:hover {
                                span {
                                    width: 80%;
                                    transition: 0.3s;
                                }
                            }
                        }  
                    }
                }
            }
        }
    }
}

@keyframes spin {

}

