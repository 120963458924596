@import '../../assets/sass/breakpoints';

.grid-container {
    position: relative;
    width: 100%;
    height: inherit;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    // grid-template-rows: repeat(1, 1fr);
    // grid-auto-rows: 100px;
    grid-gap: 20px;
    @media (min-width: $break-small) {
        // grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: repeat(3, 1fr);
    }
    @media (min-width: $break-medium) {
        grid-template-columns: repeat(12, 1fr);
        // grid-template-rows: repeat(6, 1fr);
    }
    @media (min-width: $break-large) {
        grid-template-columns: repeat(12, 1fr);
        // grid-template-rows: repeat(12, 1fr);
    }

    .test {
        grid-column: 1 / span 3;
        grid-row: 7 / 4;
        background-color: khaki;
    }
}