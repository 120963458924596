@import '../../assets/sass/breakpoints';
@import '../../assets/sass/variables';


.container {
    margin-bottom: 70px;
    min-height: min-content;

    @media (min-width: $break-small) {
        // min-height: calc(100vh - 150px);
        // padding-bottom: 0px;
    }

    .section {
        position: relative;
        z-index: -2;
        background-color: $primary;
        // margin: 0 20px;

        @media (min-width: $break-small) {
            margin: auto;
            // height: calc(100vh - 200px);

        }  
    }

    .gridAjust {
        grid-auto-rows: minmax(100px, auto);
        position: relative;

        @media (min-width: $break-small) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: $break-medium) {
            grid-template-columns: repeat(12, 1fr);
            grid-auto-rows: minmax(100px, auto);
        }

    }

    .titleContainer {
        grid-column: 1;
        grid-row: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px 0px;
        margin-top: 20px;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            grid-column: 5 / span 8;
            height: 130px;
            margin-top: 30px;
        }
        @media (min-width: $break-large) {
            grid-column: 7 / span 8;
            height: 200px;
        }

        .Title {
            width: 70%;
         
            @media (min-width: $break-medium) {
                width: 100%;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                z-index: 20;
            }
        }
    }

    

    .cardAjust {
        grid-column: 1 ;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            grid-column: 1 / span 13;
            // grid-row: 2 / span 3;
            margin-right: 20px;
            margin-left: 20px;

         }
       
    }

    .cardAjust1 {
        // letter-spacing: 1.1px;
        position: relative;
        // margin-bottom: 150px;
        z-index: 10;
        margin-top: 1.4vw;
        margin-bottom: 70px;

        @media (min-width: $break-small) {
            margin-top: 0px;
            margin-bottom: 50px;


        }

        @media (min-width: $break-medium) {
            margin-top: 0px;
        }

        @media (min-width: $break-large) {
            grid-column: 1 / span 6;
            grid-row: 1 / span 2;
            margin-top: 30px;

         }
    }


    .cardAjust2 {
        position: relative;
        z-index: 10;
        margin-bottom: 70px;
        // letter-spacing: 1.1px;

        @media (min-width: $break-medium) {
            grid-column: 6 / span 7;
             margin-bottom: 50px;

        }

        @media (min-width: $break-large) {
            grid-column: 7 / span 6;
        }
    }

    .cardAjust3 {
        // letter-spacing: 1.1px;
        position: relative;
        margin-bottom: 70px;
        z-index: 10;


        @media (min-width: $break-medium) {
            grid-column: 4 / span 9;

        }

        @media (min-width: $break-large) {

        }
    }

    .cardAjust4 {
        position: relative;
        background-color: $tertiary;
        margin-bottom: 70px;
        color: $deep-green;
        font-weight: $font-weight-second-card;
        text-align: center;
        font-weight: 700;
        font-size: 18px;

        @media (min-width: $break-medium) {
            font-size: 24px;
            // margin-top: 50px;
            grid-column: 3 / span 7;
        }
           
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            border: 20px solid $quaternary;
            border-radius: 20px;
            box-sizing: border-box;
            position: absolute;
            top: -30px;
            left: -35px;
            z-index: -1;
        }
    }


    .Flowa1 {
        position: absolute;
        grid-column: 1 ;
        top: 27%;
        width: 100%;
        max-width: 270px;
        max-width: 500px;
        // height: 50vh;
        transform: rotate(0deg);
        z-index: 2;
        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
            transform: translate(-0px, 0px) rotate(10deg);
            grid-column: 1;
        }
    }
    .Flowa2 {
        display: none;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-large) {
            display: inline-block;
            position: absolute;
            grid-column: 1;
            top: 25%;
            left: 0;
            width: 45%;
            max-width: 200px;
            transform: rotate(20deg);
            z-index: 15;
            transform: translate(-0px, 0px) rotate(10deg);
            grid-column: 1;
        }
    }

    .Flowa2b {
        position: absolute;
        grid-column: 1;
        top: 0%;
        left: -10px;
        width: 45%;
        max-width: 50px;
        transform: rotate(0deg);
        z-index: 15;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
            transform: translate(-0px, 0px) rotate(10deg);
            grid-column: 1;
        }
    }

    .Flowa3 {
        position: absolute;
        grid-column: 1;
        bottom: -40%;
        right: 2%;
        width: 25%;
        max-width: 100px;
        transform: rotate(-125deg);
        z-index: 15;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
        }
    }

    .Flowa4 {
        position: absolute;
        grid-column: 1;
        top: -100px;
        left: 2%;
        width: 40%;
        max-width: 130px;
        transform: rotate();
        z-index: 10;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
        }
    }

    .Flowa5 {
        position: absolute;
        grid-column: 1;
        bottom: 20%;
        left: -2%;
        width: 50%;
        z-index: -1;
        max-width: 200px;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
        }
    }

    .Flowa6 {
        position: absolute;
        grid-column: 1;
        bottom: -40px;
        right: -2%;
        width: 60%;
        max-width: 270px;
        z-index: 7;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
        }
    }


}