#pinContainer {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
#pinContainer .panel {
  height: 100vh;
  width: 100vw;
  position: absolute;
  text-align: center;
}

#pinContainer .panelli {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 50%;
}

.panel span {
  position: relative;
  display: block;
  top: 50%;
  font-size: 80px;
}

.panel.blue {
  background-color: #3883d8;
}

.panel.turqoise {
  background-color: #38cfd75e;
}

.panel.green {
  background-color: #22d65873;
}

.panel.bordeaux {
  background-color: #953543;
}

.second-container {
  background-color: #953543;
  display: flex;
  padding-left: 10vw;
  padding-right: 10vw;
  position: relative;
  margin-bottom: 600px;
  margin-top: 100px;
  max-width: 600px;
}






.content-block {
  display: flex;
  padding-left: 10vw;
  padding-right: 10vw;
  position: relative;
  margin-bottom: 600px;
  margin-top: 100px;
  max-width: 600px;
  background-color: #3883d8;
}

.intro-image {
  position: relative;
  z-index: 1;
  width: 200px;
  left: -100px;
  top: 100%;
  margin-top: 50px;
}

.intro-image figure {
  margin: 0;
  padding: 0;
}

.intro-image img {
  max-width: 100%;
}

.content-block .intro-text {
  position: relative;
  z-index: 2;
  width: 300px;
  line-height: 1.5;
}

#footer {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer {
    color: rgba(0, 0, 0, 0.438);
}


.footer:hover {
  color:rgb(0, 0, 0)
}
