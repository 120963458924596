@import '../../assets/sass/breakpoints';
@import '../../assets/sass/variables';


.container {
    margin-bottom: 40px;
    min-height: min-content;

    @media (min-width: $break-small) {
        padding-bottom: 0px;
    }

    .section {
        position: relative;
        // z-index: -2;
        background-color: $primary;

        @media (min-width: $break-small) {
            margin: auto;
        }

        @media (min-width: $break-medium) {
        }
  
    }

    .gridAjust {
        grid-auto-rows: minmax(100px, auto);
        position: relative;
        // max-width: fit-content;
        @media (min-width: $break-small) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: $break-medium) {
            grid-template-columns: repeat(12, 1fr);
            grid-auto-rows: minmax(100px, auto);
        }
    }

    .titleContainer {
        grid-column: 1;
        grid-row: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px 0px;
        margin-top: 20px;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            margin-top: 0px;
            grid-column: 1 / span 8;
            height: 250px;

        }

        .Title {
            width: 70%;
          

            @media (min-width: $break-medium) {
                width: 80%;
                position: absolute;
                // top: 5%;
                z-index: 20;
            }
        }
    }


    .flowerContainer1 {
        // display: none;
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
        position: relative;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }
        @media (min-width: $break-medium) {
            grid-column: 8 / span 5;
            grid-row: 1 / span 2;
              
        }


        .flower1 {
            position: absolute;
            top: -40px;
            right: -60px;
            transform: rotate(230deg);
            max-width: 200px;
            z-index: 16;
            @media (min-width: $break-medium) {
                top: -10%;
                right: 0;
                transform: rotate(-90deg);
                max-width: 400px;

            }
          
        }
    }

    .flowerContainer2 {
        grid-column: 1 / span 1;
        grid-row: 4 / span 1;
        position: relative;
        max-width: 800px;
        @media (min-width: $break-small) {
            grid-column: 1 / span 2;
        }
        @media (min-width: $break-medium) {
            grid-column: 1 / span 9;
            grid-row: 3 / span 2;
              
        }


        .flower1 {
            position: absolute;
            bottom: 0;
            // top: -40px;
            left: -60px;
            transform: rotate(50deg);
            max-width: 250px;
            z-index: 16;
            @media (min-width: $break-medium) {
                // top: -10%;
                // right: 0;
                transform: translateY(50px);
                bottom: 0;
                width: 80%;
                left: 0px;
                max-width: unset;

            }
          
        }
    }

    .cardAjust {
        position: relative;
        grid-column: 1 ;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            grid-column: 2 / span 5;
            margin-right: 20px;
            margin-left: 20px;
            margin-top: 40px;
        }
    }

    .cardAjustFirst {
        grid-row: 2 / span 1;
        @media (min-width: $break-medium) {
            
        }
    }

    .customCard {
        background-color: transparent;
        color: white;
        text-align: center;
    }

    .cardAjustAddress {
       

        grid-row: 3 / span 1;
        @media (min-width: $break-medium) {
            grid-column: 7 / span 6;
            grid-row: 3 / span 2;
            text-align: center;
            margin-bottom: 30px;
            
        }

        @media (min-width: $break-large) {
            grid-column: 8 / span 4;
            grid-row: 4 / span 2;
            margin-top: 30px;
        }


        h4 {
            margin-top: 0;
        }
    }




    /******************** CONTACT **********************/
    .cardAjustContact {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0;
        grid-column: 1 ;
        grid-row: 4 / span 1;
        margin-bottom: 40px;
        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            grid-column: 7 / span 6;
            grid-row: 2 / span 3;
            margin-right: 20px;
            margin-left: 20px;

        }

       
        h2 {
            margin: 20px 10px;
            font-size: 2rem;
            font-weight: 300;
            color: #dbdbdb;
        }

        .inputs-container {
            display: flex;
            flex-direction: column;

            @media (min-width: $break-small) {
                flex-direction: row;

            }
        
              &:last-child {
                    margin-bottom: 40px;
                }
            div {
                flex: 1 1 0;

              
                label {
                    display: none;
                }


                input, textarea {
                    width: 100%;
                    background: transparent;
                    background-color: #6A7F78;
                    color: white;
                    border: none;
                    box-sizing: border-box;
                    box-shadow: none;
                    font-size: $font-size-input;
                    font-family: $font-family;
                    transition: 0.3s;
                }

                input[type=text], input[type=number], input[type=email] {
                    height:  30px;
                    text-indent: 10px;
                    margin-bottom: 18px;
                    color: white;
                    &:focus {
                        outline: none;
                        // border-color: #2d8cf0;
                        transition: 0.3s;
                        color: white;
                    }  
                }

                input::placeholder,  textarea::placeholder {
                    color: white;
                }

               /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input[type=number] {
                    -moz-appearance: textfield;
                }
              

                input:-webkit-autofill,
                input:-webkit-autofill:hover, 
                input:-webkit-autofill:focus,
                textarea:-webkit-autofill,
                textarea:-webkit-autofill:hover,
                textarea:-webkit-autofill:focus,
                select:-webkit-autofill,
                select:-webkit-autofill:hover,
                select:-webkit-autofill:focus {
                  border: none;
                  background-color: #6A7F78;
                  color: white;
                  -webkit-text-fill-color: white;
                  -webkit-box-shadow: 0 0 0px 1000px #6A7F78 inset;
                  transition: background-color 5000s ease-in-out 0s;
                }


                textarea {
                    height:  110px;
                    text-indent: 10px;
                    resize: none;
                    &:focus {
                        outline: none;
                        transition: 0.3s;
                    }  
                }

               
            }
  
        }
        .submit {
            position: relative;
            margin-bottom: 50px;
            z-index: 10;
              
            .submit-container {
                position: absolute;
                bottom: 0;
                right: 33px;
                width: 100px;
                height: 30px;
                pointer-events: auto;

                input[type=submit] {
                    position: absolute;
                    bottom: -7px;
                    right: 0;
                    width: 92px;
                    height: 26px;
                    border-radius: 10px;
                    border: none;
                    background-color: $tertiary;
                    z-index: 100;
                    cursor: pointer;
                    &:hover {
                        cursor: pointer;
                    }
                } 

                span {
                    width: 100px;
                    height: 40px;
                    border: 10px solid $quaternary;
                    border-radius: 5px;
                    box-sizing: border-box;
                    position: absolute;
                    bottom: -8px;
                    right: 4px;
                    z-index: 5;
                }   
            
            }
            
        } 
    }
   
    .Flowa1 {
        position: absolute;
        grid-column: 1;
        bottom: 0px;
        right: 0px;
        width: calc(5 * 6vw);;
        max-width: 200px;
        transform: scaleX(-1) translateY(5 * 6vw) rotate(30deg);
        z-index: 10;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
            grid-column: 1;
        }
    }
}

