@import '../../assets/sass/breakpoints';
@import '../../assets/sass/variables';


.container {
    margin-bottom: 70px;
    min-height: min-content;


    @media (min-width: $break-small) {
        // min-height: calc(100vh - 150px);
        padding-bottom: 0px;
    }

    .section {
        position: relative;
        z-index: -2;
        background-color: $primary;
        // margin: 0 20px;

        @media (min-width: $break-small) {
            margin: auto;
            // height: calc(100vh - 200px);

        }

        @media (min-width: $break-medium) {
            // margin: auto;
            // height: calc(100vh - 250px);

        }
  
    }

    .gridAjust {
        grid-auto-rows: minmax(100px, auto);
        position: relative;

        @media (min-width: $break-small) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: $break-medium) {
            grid-template-columns: repeat(12, 1fr);
            grid-auto-rows: minmax(50px, auto);
        }
    }

    .titleContainer {
        grid-column: 1;
        grid-row: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px 0px;
        margin-top: 20px;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            grid-column: 1 / span 10;
            min-height:100px;
            margin-top: 30px;
        }
        @media (min-width: $break-large) {
            min-height:200px;
        }

        .Title {
            width: 70%;
          
            @media (min-width: $break-medium) {
                width: 75%;
                position: absolute;
                top: 5%;
                z-index: 20;
            }
        }
    }


    .cardAjust {
        grid-column: 1 ;

        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
        }

        @media (min-width: $break-medium) {
            grid-column: 1 / span 13;
            // grid-row: 2 / span 3;
            margin-right: 20px;
            margin-left: 20px;

         }
       
    }

    .cardAjust1 {
        // letter-spacing: 1.1px;
        margin-bottom: 50px;
        z-index: 16;
        position: relative;

        @media (min-width: $break-medium) {
            margin-top: 30px;
            grid-column: 1 / span 6;
            grid-row: 2 / span 1;
        }

    }

    .cardAjust2 {
        // letter-spacing: 1.1px;
        position: relative;
        margin-bottom: 70px;
        z-index: 10;

        @media (min-width: $break-medium) {
            grid-column: 2 / span 8;
            grid-row: 3 / span 1;

        }
    }

   
    .cardAjust4 {
        position: relative;
        background-color: $tertiary;
        margin-top: 70px;
        margin-bottom: 90px;
        color: $deep-green;
        font-weight: $font-weight-second-card;
        text-align: center;
        font-weight: 700;
        font-size: 18px;

        @media (min-width: $break-medium) {
            grid-column: 4 / span 8;
            margin-top: 0px;
            grid-row: 4 / span 1;
            margin-bottom: 0px;
            font-size: 24px;

        }
       
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            border: 20px solid $quaternary;
            border-radius: 20px;
            box-sizing: border-box;
            position: absolute;
            top: -30px;
            left: -35px;
            z-index: -1;
        }

        &:after {
            content: '';
            width: 200px;
            height: 200px;
            position: absolute;
            background: center / contain no-repeat url("../../assets/svg/flowa1.svg");
            top: -150px;
            left: -70px;
            // transform: rotate(-80deg);
            z-index: -1;
        }
    }

    .Flowa1 {
        position: absolute;
        grid-column: 1;
        top: 10%;
        left: 0%;
        width: 130%;
        z-index: -1;
        // max-width: 200px;
        transform: rotate(-30deg);
        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
            width: 90%;
        }
    }

    .Flowa2 {
        position: absolute;
        grid-column: 1;
        bottom: -200px;
        left: -70px;
        width: 70%;
        max-width: 220px;
        transform: rotate(0deg);
        z-index: 1;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
            transform: translate(-0px, 0px) rotate(10deg);
            grid-column: 1;
        }
    }

    .flowerContainer1 {
        display: none;
        position: relative;

        @media (min-width: $break-medium) {
            display: block;
            grid-column: 1 / span 10;
            grid-row: 2 / span 4;
        }

        .flower1 {
            position: absolute;
            top: -100px;
            // max-width: 900px;
            transform: rotate(-20deg);
        }
    }

    .flowerContainer2 {
        display: none;
        position: relative;

        @media (min-width: $break-medium) {
            display: block;
            grid-column: 9 / span 4;
            grid-row: 2 / span 5;
        }

        .flower1 {
            // background-color: black;
            position: absolute;
            max-width: 450px;
            width: 100%;
            height: 100%;
            top: 15%;
            right: 0;

            img {
                transform: rotate(20deg);
            }
        }

        .flower2 {
            position: absolute;
            max-width: 500px;
            width: 100%;
            top: 50%;
            right: 20%;
            transform: rotate(-8deg);
        }
    }

    .Flowa3 {
        position: absolute;
        grid-column: 1;
        bottom: -30px;
        right: -30px;
        width: 30%;
        max-width: 150px;
        -webkit-transform: rotate(0deg) scaleX(-1);
        transform: rotate(45deg) scaleX(-1);
        z-index: 15;

        @media (min-width: $break-small) {
           
        }

        @media (min-width: $break-medium) {
            grid-column: 1;
            display: none;
        }
    }
}