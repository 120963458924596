@import '../../assets/sass/breakpoints';

.app-container {
    background-color: khaki;
    .app-centred-container {
        &:first-child {
            width: 100%;
            @media (min-width: $break-medium) {
                width: 90%;
                margin: auto;
            }

            @media (min-width: $break-large) {
                max-width: 1900px;
                margin: auto;

            }
        }
    } 

}