@import '../../assets/sass/breakpoints';
@import '../../assets/sass/variables';


.container {
    padding-bottom: 40px;
    min-height: min-content;

    @media (min-width: $break-small) {
        min-height: calc(100vh - 150px);

    }

    .section {
        background-color: $primary;
        position: relative;
        z-index: -2;
        @media (min-width: $break-small) {
            margin: auto;
            min-height: calc(100vh - 200px);

        }

        @media (min-width: $break-medium) {
            margin: auto;
            min-height: calc(100vh - 250px);

        }

        .Flowa1 {
            grid-column: 1;
            width: 80%;
            height: 50vh;
            transform: translate(-80px, 20px) rotate(-15deg);

            @media (min-width: $break-small) {
                position: absolute;
                width: 100%;
                min-width: 400px;
                transform: translate(-40px, 20px) rotate(-15deg);
            }

            @media (min-width: $break-medium) {
                transform: translate(-0px, 0px) rotate(10deg);
                grid-column: 1 / span 4;
            }
        }
        .Flowa2 {
            grid-column: 1;
            position: absolute;
            width: 30%;
            margin-top: 30px;
            margin-left: 7px;

            @media (min-width: $break-medium) {
                // transform: translate(-0px, 0px) rotate(10deg);
                grid-column: 1 / span 3;
                grid-row: 1;
                top: 10%;
                width: 76px;
            }
        }

        .Flowa3 {
            grid-column: 1;
            position: absolute;
            top: 30%;
            width: 70%;
            max-width: 300px;
            transform: translate(-30px, 70px);
            z-index: 7;

            @media (min-width: $break-small) {
                grid-row: 2;
                transform: translate(-30px, -100px);
            }

            @media (min-width: $break-medium) {
                top: 14%;
                width: 100%;
                grid-column: 1  / span 2;
                grid-row: 2;
                transform: unset;

            }
        }

        .Title1Container {
            background-color: blue;
            grid-column: 1;
            grid-row: 1 / span 2;
            position: absolute;
            top: 7%;
            height: 100%;
            width: 100%;
            max-height: inherit;
            background: center / contain no-repeat url("../../assets/svg/title1.svg");
            z-index: 10;
            margin-top: 30px;

            @media (min-width: $break-small) {
                margin-top: 30px;
                grid-column: 2 / span 2;
                grid-row: 1 / span 2;

            }

            @media (min-width: $break-medium) {
                margin-top: 0px;

                top: 0%;
                grid-column: 2 / span 10;
                grid-row: 2 / span 2;
                background-color: blue;
                background: center / contain no-repeat url("../../assets/svg/title1.svg");
            }
        }

        .Flowa5Container {
            position: relative;
            grid-column: 1;
            grid-row: 1;
            z-index: 5;

            @media (min-width: $break-small) {
                grid-column: 1 / span 3;
                grid-row: 1;
            }

            @media (min-width: $break-medium) {
                grid-column: 1 / span 6;
                grid-row: 1 / span 6;
             }

            img {
                height: inherit;
                position: absolute;
                top: 80%;
                transform: translateY(0px) rotate(-4
                10deg);
                max-width: 400px;

                @media (min-width: $break-small) {
                    top: 20%;
                 }
                
            @media (min-width: $break-medium) {
                max-width: 500px;
                bottom: 0;
                top: unset;

             }
            }
        }

        .Flowa6Container {
            position: relative;
            grid-column: 12 / span 1;
            z-index: 5;

            @media (max-width: $break-medium) {
               display: none;
            }

            img {
                height: inherit;
                position: absolute;
                max-width: 250px;
                transform: translateY(-40px) rotate(20deg);
            }
        }
        .Flowa7Container {
            position: relative;
            grid-column: 11 / span 2;
            z-index: 5;

            @media (max-width: $break-medium) {
               display: none;
            }

            img {
                height: inherit;
                position: absolute;
                right: 0;
                max-width: 250px;
                transform: translate(20px, -40px) rotate(-110deg);
            }
        }
        .Flowa8Container {
            position: relative;
            grid-column: 9 / span 4;
            grid-row: 2 / span 5;
            z-index: 5;
            z-index: 0;

            @media (max-width: $break-medium) {
                display: none;
             }
           

            .Flowa7 {
                height: inherit;
                position: absolute;
                right: 0;
                max-width: 160px;
                transform: translate(20px, -40px) rotate(-110deg);
            }
            .Flowa8 {
                height: inherit;
                position: absolute;
                top: 20%;
                right: 15%;
                max-width: 260px;
                transform: translate(20px, -40px) rotate(10deg);
            }
            .Flowa9 {
                height: inherit;
                position: absolute;
                top: 32%;
                right: 0;
                max-width: 360px;
                transform:  rotate(-30deg);
            }
            .Flowa10 {
                height: inherit;
                position: absolute;
                top: 42%;
                right: 0;
                width: 100%;
                max-width: 260px;
                transform:  rotate(-30deg);
            }
            .Flowa11 {
                height: inherit;
                position: absolute;
                top: 72%;
                right: 37%;
                max-width: 80px;
                transform:  rotate(130deg);
            }


        }
       
    }

    .gridAjust {
        grid-auto-rows: minmax(160px, auto);
        position: relative;

        @media (min-width: $break-small) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: $break-medium) {
            grid-template-columns: repeat(12, 1fr);
            grid-auto-rows: minmax(100px, auto);
        }
    }

    .cardContainer {
        grid-row: 3 / span 1;
        position: relative;
        z-index: 10;
        margin-bottom: 60px;


        @media (min-width: $break-small) {
            grid-column: 1 / span 3;
            grid-row: 4 / span 1;
            margin-bottom: 40px;

        }

        @media (min-width: $break-medium) {
            grid-column: 3 / span 8;
            grid-row: 5 / span 1;
            // transform: translateX(40px);
        }

        .cardAjust {
            // position: absolute;
            // z-index: 10;
            // letter-spacing: 1.1px;
            @media (min-width: $break-small) {
                transform: translateY(0);
            }
        }
        .Flowa6 {
            position: absolute;
            bottom: -20px;
            z-index: 0;
            right: 0;
            width: 70%;
            max-width: 300px;
        }
    }



    .cardAjustYellow {
        position: relative;
        background-color: $tertiary;
        color: $deep-green;
        font-weight: $font-weight-second-card;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        grid-row: 4 / span 1 ;
        grid-column: 1;
        margin-bottom: 50px;

        @media (min-width: $break-small) {
            grid-row: 5 / span 1 ;
            grid-column: 1 / span 3;
            margin-bottom: 70px;
        }

        @media (min-width: $break-medium) {
            grid-row: 7 / span 2 ;
            grid-column: 3 / span 8;
            margin-bottom: 0px;
            font-size: 24px;

        }

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            border: 20px solid $quaternary;
            border-radius: 20px;
            box-sizing: border-box;
            position: absolute;
            top: -30px;
            left: -35px;
            z-index: -1;
        }

        @media (max-width: $break-medium) {
            &:after {
                content: '';
                width: 100px;
                height: 100px;
                position: absolute;
                background: center / contain no-repeat url("../../assets/svg/flowa1.svg");
                top:-70px;
                right: -50px;
                transform: rotate(-80deg);
                z-index: 0;
            }
        }
       
    }
    
}

