@import '../../assets/sass/variables';
@import '../../assets/sass/breakpoints';


.card-container {
    background-color: $secondary;
    border-radius: 20px;
    padding: 40px 30px;
    margin: 0px 20px;
    text-align: center;
    color: $font-color-card;
    height: min-content;
    letter-spacing: 1.1px;

    @media (min-width: $break-medium) {
        margin: 0px;
        text-align: justify;

    }
}