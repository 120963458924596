@import '../../assets/sass/breakpoints';

.app-container {
    .app-centred-container {
        &:first-child {
            width:90%;
            margin: auto;
            // max-width: 100vw;
            // overflow: hidden;
            // @media (min-width: $break-medium) {
            //     width: 90%;
            //     margin: auto;
            // }

            @media (min-width: $break-large) {
                max-width: 1900px;
                // margin: auto;

            }
        }
    } 

}